import React from "react"
import { footer } from "../../data/Data"
import "./footer.css"

const Footer = () => {
  return (
    <>
      <section className='footerContact'>
        <div className='container'>
          <div className='send flex'>
            <div className='text'>
              <h1>Do You Have Questions ?</h1>
              <p>We'll help you to grow your Business and growth.</p>
            </div>
            <button className='btn5' ><a href='/contact' style={{color:"black",textDecorationLine:"none", textDecoration:"no-underline"}}>Contact Us Today</a></button>
          </div>
        </div>
      </section>

      <footer>
        <div className='container'>
          <div className='box'>
            <div className='logo'>
              <img src='../images/logo.jpg' alt='' />
              <h2>Do You Need Help With Anything?</h2>
              <h3>Mahadev Industries</h3>
              <p>254,Bera Sankhla Krishi firm,
                <br />
                Kantaliya Dist Pali,Rajasthan 306103
                <br />
                GSTIN/UIN :08BKSPM8209E1ZS
                <br />
                State Name :Rajasthan,code:08
              </p>
               

              {/* <div className='input flex'>
                <input type='text' placeholder='Email Address' />
                <button>Subscribe</button>
              </div> */}
            </div>
          </div>

          {footer.map((val) => (
            <div className='box'>
              <h3>{val.title}</h3>
              <ul>
                {val.text.map((items) => (
                  <li> {items.list} </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </footer>
      <div className='legal'>
        <span>© 2024 All right reserved By Mahadev Industries.</span>
      </div>
    </>
  )
}

export default Footer
