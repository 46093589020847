import React from "react";
import img from "../images/service.jpg";
import img2 from "../images/serviceheading.jpg";
import Back from "../common/Back";
import "../home/featured/Featured.css";
import FeaturedCard from "../home/featured/FeaturedCard";

const Services = () => {
  return (
    <>
      <section className="services mb">
        <Back name="Services" title="Services -All Services" cover={img2} />
        {/* <div className="featured container">
          <div>
          <h1>Installation & Training</h1>
          <p>
            The key to a long-lasting plumbing system is an efficient
            installation. We’ve compiled a ready set of resources to guide you
            through crucial aspects of installing our products and accessories.
          </p>
          </div>
          <div style={{display:"flex"}}>
            <div style={{width:"50%"}}>
          <h1>Custom Solutions</h1>
          <br />
          <p>
            Trying to work through a unique challenge? Rest assured, we have all
            the answers you need. Our qualified team of technical experts is
            ready to jump in and lend their on-the-ground experience and deliver
            the right solution, accurate installation advice as well as
            prolonged support.
          </p>
          </div>
          <img src='../images/banner.jpg' alt='' />
          <div style={{width:"50%",height:"100%"}}>
          
          </div>
          </div>
          <FeaturedCard />
        </div> */}
        <div className="container flex mtop">
          <div className="left row">
            

            <h1>Installation & Training</h1>
            <p>
              The key to a long-lasting plumbing system is an efficient
              installation. We’ve compiled a ready set of resources to guide you
              through crucial aspects of installing our products and
              accessories.
            </p>

            <h1>Custom Solutions</h1>
          <br />
          <p>
            Trying to work through a unique challenge? Rest assured, we have all
            the answers you need. Our qualified team of technical experts is
            ready to jump in and lend their on-the-ground experience and deliver
            the right solution, accurate installation advice as well as
            prolonged support.
          </p>
            
          </div>
          <div className="right row">
            <img src={img} alt="" />
          </div>
        </div>
      </section>
    </>
  );
};

export default Services;
