import React from 'react'
 
import DarkVariantExample from './Carosal';
export const Sections =()=> {
  


  return (
    <>
 
      {/* <img src="../images/banner.jpg" class="d-block w-100" alt="..."/> */}
     <DarkVariantExample/>
     </>
  )
}

