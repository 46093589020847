import React from "react"
import ResponsiveDemo from "../home/recent/RecentCarsoule"
import {list} from "../data/Data"
const Heading = ({ title, subtitle }) => {
  return (
    <>
      <div className='heading'>
        <h1>Don't just buy a tank. Buy the future</h1>
        {/* <p>{subtitle} </p> */}
        
      </div>
      {/* <ResponsiveDemo list ={list}/> */}
    </>
  )
}

export default Heading
