import React from "react";
import Back from "../common/Back";
import Heading from "../common/Heading";
import img from "../images/aboutAvatar.jpg";
import "./about.css";

const About = () => {
  return (
    <>
      <section className="about">
        <Back name="About Us" title="About Us - Who We Are?" cover={img} />
        <div className="container flex mtop">
          <div className="left row">
            <Heading
              title="Our Agency Story"
              subtitle="Check out our company story and work process"
            />

            <h1>Ashirvad - A Legacy of Trust</h1>
            <p>
              Ashirvad Pipes has been the benchmark of quality in the Indian
              water management industry since 1998. We adhere to the highest
              international standards set for manufacturing products for potable
              water supply through smart solutions and intelligent technologies
              that ensure sustainability and convenience.
            </p>

            <h1> Aliaxis - A Reputation for Excellence</h1>
            <p>
              {" "}
              Headquartered in Belgium, Aliaxis has earned the distinction of
              providing the finest integrated & sustainable fluid handling
              systems across the globe. Our best practices include deploying
              environment-friendly technology, using non-toxic raw material, and
              reducing the carbon footprint in a conscious and conscientious
              way. Our group is deeply committed to grow Ashirvad Pipes in
              India.
            </p>
            

            <h1>Our Vision</h1>
            <p>
              Be the most admired company in our category for customer intimacy,
              innovative products, solutions and practices, best-in-class
              commercial and operational excellence, and people practices that
              make us the “best place to work”.{" "}
            </p>
            <h1>Our Mission</h1>
            <p>
              We endeavor to become a preferred employer among high caliber
              professionals hailing from all walks of life. We expect and
              empower our teams to lend their thoughts and share their myriad
              experiences to enrich the social fabric of the organisation for a
              long time to come.
            </p>
            <button className="btn2">More About Us</button>
          </div>
          <div className="right row">
            <img src="./IMG-20240723-WA0033.jpg" alt="" />
          </div>
        </div>
      </section>
    </>
  );
};

export default About;
